/* global WOW */
/* jshint loopfunc: true */

(function () {
    "use strict";

    applyJayBar('.topbar');

    new WOW({
        live: true,
        offset: 20
    }).init();

    function isMobile() {
        return /(Android|webOS|Phone|iPad|iPod|BlackBerry|Windows Phone)/i.test(navigator.userAgent);
    }

    $('.footer-year').text(function () {
        return new Date().getFullYear();
    });

    $(window).on("load", function() {
        setTimeout(function () {
            if (!$('#carbonads').size() && !isMobile()) {
                $('.sponsors').load('/partials/adblocked.html');
            }
        }, 10000);

        if (isMobile()) {
            $('html').addClass('mobile');
        }

        $('body').addClass('loaded');
    });

    $(function () {
        if (isMobile()) {
            $('html').addClass('mobile');
        }

        $(window).on('scroll', function () {
            var opacity = 1 - ($('body').scrollTop() - 70) / 100;

            if (opacity >= 0) {
                $('.logo').css('opacity', opacity);
            }
        }).on('resize', function () {
            $('.portrait, .face-img').remove();
        });

        // Topbar Menu
        $('.topbar-menu-button').on('click', function (e) {
            $(this).toggleClass('topbar-menu-button-active');

            $('.topbar-menu').toggleClass('topbar-menu-open');

            e.preventDefault();
        });

        $.ajax({
            url: 'https://jaysalvat.github.io/jquery.facedetection/releases/latest/metadata.js',
            dataType: 'jsonp',
            jsonpCallback: '__metadata',
            cache: true,
            success: function (data) {
                $('#version-number').text(data.version);
                $('#version-date').text(data.date.split(' ')[0]);
            }
        });
    });
})();
